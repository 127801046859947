import React, { useState, useEffect } from 'react';
import { firestore } from './firebase-config';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';

function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, orderBy('buttonScore', 'desc'), limit(50));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const leaderboardData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          username: data.username || "Unknown", // Fetch username from document data
          buttonScore: data.buttonScore || 0, // Default to 0 if no score exists
        };
      });

      setLeaderboard(leaderboardData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading leaderboard...</div>;
  }

  return (
    <div className="leaderboard">
      <h1>Leaderboard</h1>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Username</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((user, index) => (
            <tr key={index}>
              <td>{index + 1}</td> {/* Rank starts from 1 */}
              <td>{user.username}</td> {/* Fetch from document data, not the doc ID */}
              <td>{user.buttonScore}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Leaderboard;
