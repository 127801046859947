import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { collection, addDoc, query, getDocs, orderBy, doc } from "firebase/firestore";
import MarkdownIt from "markdown-it";
import { AuthContext } from './AuthContext';
import { getFirestore } from "firebase/firestore";

const firestore = getFirestore();
const md = new MarkdownIt();
const MAX_CONTENT_LENGTH = 200;

function Post({
  post,
  loggedInUser,
  onLikeDislike,
  onDelete,
  isAdmin,
}) {
  const [postState, setPostState] = useState(post);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [showComments, setShowComments] = useState(false);

  const fetchComments = async () => {
    try {
      const threadRef = doc(firestore, "threads", post.id);
      const commentsRef = collection(threadRef, "comments");
      const q = query(commentsRef, orderBy("createdAt", "asc"));
      const querySnapshot = await getDocs(q);
      const commentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(commentsData);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  const handleAddComment = async (parentId = null) => {
    if (!newComment.trim()) return;

    try {
      const threadRef = doc(firestore, "threads", post.id);
      const commentsRef = collection(threadRef, "comments");
      await addDoc(commentsRef, {
        content: newComment,
        createdAt: new Date(),
        username: loggedInUser.username,
        userId: loggedInUser.email,
        parentId,
      });
      setNewComment("");
      fetchComments();
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const renderComments = (commentsList, parentId = null) => {
    return commentsList
      .filter((comment) => comment.parentId === parentId)
      .map((comment) => (
        <div key={comment.id} className={`comment ${parentId ? "reply" : ""}`}>
          <p>
            <strong>{comment.username}</strong>: {comment.content}
          </p>
          <p className="comment-date">
            {new Date(comment.createdAt.seconds * 1000).toLocaleString()}
          </p>
          <button
            onClick={() => handleAddComment(comment.id)}
            className="reply-button"
          >
            Reply
          </button>
          {renderComments(comments, comment.id)}
        </div>
      ));
  };

  const toggleShowComments = () => {
    setShowComments((prevState) => !prevState);
  };

  const handleLikeDislike = (action) => {
    const updatedPost = { ...postState };

    if (action === "like") {
      if (updatedPost.likedBy.includes(loggedInUser.username)) {
        updatedPost.likes -= 1;
        updatedPost.likedBy = updatedPost.likedBy.filter(
          (user) => user !== loggedInUser.username
        );
      } else {
        updatedPost.likes += 1;
        updatedPost.likedBy.push(loggedInUser.username);
        if (updatedPost.dislikedBy.includes(loggedInUser.username)) {
          updatedPost.dislikes -= 1;
          updatedPost.dislikedBy = updatedPost.dislikedBy.filter(
            (user) => user !== loggedInUser.username
          );
        }
      }
    } else if (action === "dislike") {
      if (updatedPost.dislikedBy.includes(loggedInUser.username)) {
        updatedPost.dislikes -= 1;
        updatedPost.dislikedBy = updatedPost.dislikedBy.filter(
          (user) => user !== loggedInUser.username
        );
      } else {
        updatedPost.dislikes += 1;
        updatedPost.dislikedBy.push(loggedInUser.username);
        if (updatedPost.likedBy.includes(loggedInUser.username)) {
          updatedPost.likes -= 1;
          updatedPost.likedBy = updatedPost.likedBy.filter(
            (user) => user !== loggedInUser.username
          );
        }
      }
    }

    setPostState(updatedPost);
    onLikeDislike(post.id, action);
  };

  const toggleExpand = () => {
    setPostState((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
    }));
  };

  return (
    <div key={postState.id} className="post-container">
      <NavLink to={`/profile/${postState.username}`} className="nav-link">
        <h3 className="post-username">{postState.username}</h3>
      </NavLink>

      {/* <div
        dangerouslySetInnerHTML={{
          __html: md.render(
            postState.isExpanded
              ? postState.content
              : `${postState.content.slice(0, MAX_CONTENT_LENGTH)}`
          ),
        }}
        className="post-content"
      /> */}
      <div
  dangerouslySetInnerHTML={{ __html: postState.isExpanded
    ? postState.content
    : `${postState.content.slice(0, MAX_CONTENT_LENGTH)}` }}
  className="post-content"
/>
      {postState.content.length > MAX_CONTENT_LENGTH && (
        <button onClick={toggleExpand} className="expand-button">
          {postState.isExpanded ? "Show Less" : "Show More"}
        </button>
      )}

      <div className="post-actions">
        <div>
          <button
            onClick={() => handleLikeDislike("like")}
            className={`like-button ${
              loggedInUser && postState.likedBy.includes(loggedInUser.username)
                ? "active"
                : ""
            }`}
          >
            👍 {postState.likes}
          </button>
          <button
            onClick={() => handleLikeDislike("dislike")}
            className={`dislike-button ${
              loggedInUser && postState.dislikedBy.includes(loggedInUser.username)
                ? "active"
                : ""
            }`}
          >
            👎 {postState.dislikes}
          </button>
        </div>
        {isAdmin && (
          <button onClick={() => onDelete(postState.id)} className="delete-button">
            Delete
          </button>
        )}
      </div>

      <p className="post-date">
        {new Date(postState.createdAt.seconds * 1000).toLocaleString()}
      </p>

      <div className="comments-section">
        <button onClick={toggleShowComments} className="toggle-comments-button">
          {showComments ? "Hide Comments" : "Show Comments"}
        </button>
        {showComments && (
          <>
            <h4>Comments</h4>
            <textarea
              placeholder="Write a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              className="comment-input"
            />
            <button onClick={() => handleAddComment()} className="add-comment-button">
              Add Comment
            </button>
            <div className="comments-list">{renderComments(comments)}</div>
          </>
        )}
      </div>
    </div>
  );
}

export default Post;
