import React, { createContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { firestore } from "./firebase-config";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [buttonScore, setButtonScore] = useState(0);
  const [rank, setRank] = useState("User");
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedInUser(user);
        handleUserOnlineStatus(user.uid, true);
        fetchUserData(user.uid);
      } else {
        setLoggedInUser(null);
        setLoading(false);
      }
    });

    return () => {
      if (loggedInUser?.uid) {
        handleUserOnlineStatus(loggedInUser.uid, false);
      }
      unsubscribe();
    };
  }, [auth]);

  // Fetch user data from Firestore using UID
  const fetchUserData = async (uid) => {
    try {
      const userRef = doc(firestore, "users", uid);
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        const data = userDoc.data();
        setButtonScore(data.buttonScore || 0);
        setRank(data.rank || "User");
  
        // Store username in loggedInUser
        setLoggedInUser(prevState => ({
          ...prevState,
          username: data.username || "Guest", // Set the username from Firestore (or "Guest" if not available)
        }));
      } else {
        await setDoc(userRef, { buttonScore: 0, rank: "User", online: false }, { merge: true });
        setButtonScore(0);
        setRank("User");
        setLoggedInUser(prevState => ({
          ...prevState,
          username: "Guest", // Set the username to "Guest" if the user is new
        }));
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  // Update button score
  const updateButtonScore = async (newScore) => {
    if (!loggedInUser || !loggedInUser.uid) {
      console.error("User UID is not available.");
      return;
    }

    try {
      const userRef = doc(firestore, "users", loggedInUser.uid);
      await updateDoc(userRef, { buttonScore: newScore });
      setButtonScore(newScore);
    } catch (error) {
      console.error("Error updating button score:", error);
    }
  };

  // Update user rank
  const updateRank = async (newRank) => {
    if (!loggedInUser || !loggedInUser.uid) {
      console.error("User not logged in.");
      return;
    }

    try {
      const userRef = doc(firestore, "users", loggedInUser.uid);
      await updateDoc(userRef, { rank: newRank });
      setRank(newRank);
    } catch (error) {
      console.error("Error updating rank:", error);
    }
  };

  // Manage online status
  const handleUserOnlineStatus = async (uid, isOnline) => {
    try {
      const userRef = doc(firestore, "users", uid);
      await updateDoc(userRef, { online: isOnline });

      if (isOnline) {
        window.addEventListener("beforeunload", async () => {
          await updateDoc(userRef, { online: false });
        });
      }
    } catch (error) {
      console.error("Error updating user online status:", error);
    }
  };

  // Login function
  const login = async (email, password) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  // Logout function
  const logout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setLoggedInUser(null);  // Ensure user is logged out in context
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loggedInUser,
        buttonScore,
        updateRank,
        updateButtonScore,
        rank,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
