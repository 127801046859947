import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Post from "./Post";
import React, { useState, useContext, useEffect } from 'react';
import { firestore } from './firebase-config';
import {
  collection,
  addDoc,
  query,
  getDocs,
  orderBy,
  limit,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { AuthContext } from './AuthContext';
import MarkdownIt from 'markdown-it';
import Sidebar from './sidebar';
import UserProfile from "./UserProfilePage";
import ButtonGamePage from "./ButtonPage";

const md = new MarkdownIt();
const MAX_CONTENT_LENGTH = 200;

function CommunityPage() {
  const { loggedInUser, rank } = useContext(AuthContext);
  const [threads, setThreads] = useState([]);
  const [newThread, setNewThread] = useState('');
  const [message, setMessage] = useState('');
  const [sortBy, setSortBy] = useState('createdAt'); // "likes" or "createdAt"
  const [sortOrder, setSortOrder] = useState('desc'); // "asc" or "desc"

  // Fetch threads
  const fetchThreads = async () => {
    try {
      const threadsRef = collection(firestore, 'threads');
      const q = query(threadsRef, orderBy(sortBy, sortOrder), limit(100));
      const querySnapshot = await getDocs(q);
      const threadsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setThreads(threadsData);
    } catch (error) {
      console.error('Error fetching threads:', error);
      setMessage('Error fetching threads. Please try again.');
    }
  };

  useEffect(() => {
    fetchThreads();
  }, [sortBy, sortOrder]);

  const handleSortChange = (event) => {
    const [newSortBy, newSortOrder] = event.target.value.split('-');
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  };

  const handleCreateThread = async () => {
    if (!newThread.trim()) {
      setMessage('Please enter a thread message.');
      return;
    }

    if (!loggedInUser) {
      setMessage('You must be logged in to create a thread. Log in from the /home/ page.');
      return;
    }

    try {
      const threadsRef = collection(firestore, 'threads');
      await addDoc(threadsRef, {
        content: newThread,
        createdAt: new Date(),
        username: loggedInUser.username,
        userId: loggedInUser.email,
        likes: 0,
        dislikes: 0,
        likedBy: [],
        dislikedBy: [],
      });

      setNewThread('');
      setMessage('Thread created successfully!');
      fetchThreads();
    } catch (error) {
      console.error('Error creating thread:', error);
      setMessage('Error creating thread. Please try again.');
    }
  };

  const handleLikeDislike = async (threadId, action) => {
    if (!loggedInUser || !loggedInUser.username) {
      setMessage('You must be logged in with a valid username to like or dislike.');
      return;
    }

    try {
      const threadRef = doc(firestore, 'threads', threadId);
      const threadDoc = await getDoc(threadRef);

      if (threadDoc.exists()) {
        const data = threadDoc.data();

        // Ensure likedBy and dislikedBy are always arrays
        const likedBy = Array.isArray(data.likedBy) ? data.likedBy : [];
        const dislikedBy = Array.isArray(data.dislikedBy) ? data.dislikedBy : [];

        if (action === 'like') {
          if (likedBy.includes(loggedInUser.username)) {
            await updateDoc(threadRef, {
              likes: data.likes - 1,
              likedBy: likedBy.filter((user) => user !== loggedInUser.username),
            });
          } else {
            if (dislikedBy.includes(loggedInUser.username)) {
              await updateDoc(threadRef, {
                dislikes: data.dislikes - 1,
                dislikedBy: dislikedBy.filter((user) => user !== loggedInUser.username),
              });
            }
            await updateDoc(threadRef, {
              likes: data.likes + 1,
              likedBy: [...likedBy, loggedInUser.username],
            });
          }
        } else if (action === 'dislike') {
          if (dislikedBy.includes(loggedInUser.username)) {
            await updateDoc(threadRef, {
              dislikes: data.dislikes - 1,
              dislikedBy: dislikedBy.filter((user) => user !== loggedInUser.username),
            });
          } else {
            if (likedBy.includes(loggedInUser.username)) {
              await updateDoc(threadRef, {
                likes: data.likes - 1,
                likedBy: likedBy.filter((user) => user !== loggedInUser.username),
              });
            }
            await updateDoc(threadRef, {
              dislikes: data.dislikes + 1,
              dislikedBy: [...dislikedBy, loggedInUser.username],
            });
          }
        }

        fetchThreads();
      }
    } catch (error) {
      console.error('Error updating likes/dislikes:', error);
      setMessage('An error occurred while updating the thread.');
    }
};

  const handleDeleteThread = async (threadId) => {
    if (window.confirm('Are you sure you want to delete this thread?')) {
      try {
        const threadRef = doc(firestore, 'threads', threadId);
        await deleteDoc(threadRef);
        setThreads(threads.filter((thread) => thread.id !== threadId));
        setMessage('Thread deleted successfully!');
      } catch (error) {
        console.error('Error deleting thread:', error);
        setMessage('Error deleting thread.');
      }
    }
  };

  const toggleExpand = (threadId) => {
    setThreads((prevThreads) =>
      prevThreads.map((thread) =>
        thread.id === threadId
          ? { ...thread, isExpanded: !thread.isExpanded }
          : thread
      )
    );
  };

  return (
    <div style={{ display: 'flex', margin: 'auto' }}>
      <Sidebar />
      <div style={{ padding: '20px', maxWidth: '700px', margin: 'auto', marginLeft: '100px' }}>
        
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Community Page</h1>
        <p style={{ textAlign: 'center' }}>
          Welcome, {loggedInUser ? loggedInUser.username : 'Guest'} {rank && `(${rank})`}
        </p>

        <div style={{ marginBottom: '20px', minWidth: '700px' }}>
          <h2>Create a Post</h2>
          {/* <textarea
            placeholder="Write your thread here..."
            value={newThread}
            onChange={(e) => setNewThread(e.target.value)}
            style={{
              width: '93.5%',
              padding: '10px',
              marginBottom: '10px',
              borderRadius: '5px',
              border: '1px solid #ddd',
              backgroundColor: 'rgb(24, 24, 24)',
              color: 'white'
            }}
          /> */}
          <ReactQuill
  theme="snow"
  value={newThread}
  onChange={setNewThread}
  style={{ backgroundColor: "rgb(24, 24, 24)", color: "white", marginBottom: '10px' }}
/>
          <button
            onClick={handleCreateThread}
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              // borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Create Thread
          </button>
        </div>

        {message && (
          <p style={{ color: 'green', textAlign: 'center', marginBottom: '20px' }}>
            {message}
          </p>
        )}

        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <label htmlFor="sort">Sort by: </label>
          <select
            id="sort"
            value={`${sortBy}-${sortOrder}`}
            onChange={handleSortChange}
          >
            <option value="likes-desc">Likes (High to Low)</option>
            <option value="likes-asc">Likes (Low to High)</option>
            <option value="createdAt-desc">Date (Newest First)</option>
            <option value="createdAt-asc">Date (Oldest First)</option>
          </select>
        </div>

        <h2>Thread Posts</h2>
<div style={{ maxHeight: "400px", overflowY: "auto", paddingRight: "10px" }}>
  {threads.length > 0 ? (
    threads.map((thread) => (
      <Post
        key={thread.id}
        post={thread}
        loggedInUser={loggedInUser}
        onLikeDislike={handleLikeDislike}
        onExpandToggle={(id) =>
          setThreads((prevThreads) =>
            prevThreads.map((t) =>
              t.id === id ? { ...t, isExpanded: !t.isExpanded } : t
            )
          )
        }
        onDelete={handleDeleteThread}
        isAdmin={rank === "Admin"}
      />
    ))
  ) : (
    <p>No threads available yet.</p>
  )}
</div>
      </div>
    </div>
  );
}

export default CommunityPage;
